@import 'styles/vars2';

.checkoutButton {
    background: $primary-yellow;
    display: inline-block;
    padding: 2rem 4rem;
    border-radius: 4px;
    color: darken($primary-yellow, 50);
    font-size: 1.6rem;
    position: absolute;
    right: 0;
    top: 0;
}
.checkoutButton:hover {
    background: darken($primary-yellow, 10);
}

.cartClose {
    display: none;
}
.cartContainer {

    .cartHeader {
        display: grid;
        grid-template-columns: 180px 1fr 1fr 1fr 1fr;
        gap: 2rem;
        border-bottom: 1px solid #eee;
        padding-bottom: 2rem;

        .productQuantity,
        .productPoints,
        .productPointsTotal {
            text-align: right;
        }

        .productName,
        .productQuantity,
        .productPoints,
        .productPointsTotal {
            color: $primary-blue-dark;
            font-weight: bold;
        }

    }

    .cartTotal {
        text-align: right;
        font-weight: bold;
        margin-top: 2rem;
    }


    .cartLine {

        .basicQuantity { display: none; }

        display: grid;
        grid-template-columns: 180px auto;
        gap: 2rem;
        border-bottom: 1px solid #eee;
        padding: 2rem 0;

        .productInfoContainer {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 2rem;
        }

        .productImageContainer--cart {
            position: relative;
            width: 160px;
            height: 160px;
        }

        .productName {
            font-size: 1.6rem;
            font-weight: bold;
            color: rgba(0,0,0,0.75);
        }

        // .productOption {
        //     margin-right: 1rem;
        // }


        .productDetails,
        .productPoints,
        .productPointsTotal,
        .productQuantityContainer,
        .productRemove {
            display: flex;
            align-items: center;
        }

        .productQuantityContainer,
        .productPoints,
        .productPointsTotal {
            justify-content: flex-end;
        }

        .productPointsTotal {
            font-weight: bold;
        }

        .removeCartItemButton {
            text-align: left;
            padding: 0;
            margin: 0;
            outline: none;
            appearance: none;
            border: none;
            background: none;
            display: inline-block;
            cursor: pointer;
            color: rgba(0,0,0,0.55);
            font-weight: bold;
            // letter-spacing: 0.02em;
            // text-transform: uppercase;
            font-size: 1.1rem;
            line-height: 24px;
        }
        .removeCartItemButton:hover {
            color:rgba(0,0,0,0.85);
        }

        .productQuantityContainer {
            .productQuantityButton {
              outline: none;
              appearance: none;
              border: 1px solid #eee;
              background: none;
              width: 3rem;
              height: 3rem;
              border-radius: 0;
              cursor: pointer;
            }
            .productQuantity {
              width: 3rem;
              height: 3rem;
              text-align: center;
              line-height: 3rem;
            }
          }

    }

}

.cartOverlayContainer {
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    width: 100%;
    width: 480px;
    max-width: 100%;
    box-sizing: border-box;
    box-shadow: -3px 0 12px rgba(0,0,0, 0.2);
    background: #fff;
    overflow-y: auto;
    padding: 0 2rem 2rem 2rem;

    .cartHeader {
        display: none;
    }

    .checkoutButton--top {
        // right: 5rem;
        display: none;
    }

    .cartClose {
        display: block;
        position: absolute;
        right: 1rem;
        top: 1rem;
        outline: none;
        appearance: none;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        border: none;
        background: #eee;
        i {
            line-height: 36px;
        }
        cursor: pointer;
    }
    .cartClose:hover {
        background: #ccc;
    }

    .installerBalance {
        display: none;
    }

    .contentContainer--cart {

        .cartLine {
            display: grid;
            grid-template-columns: 80px auto;

            .productImageContainer--cart {
                width: 80px;
                height: 80px;
            }

            .basicQuantity {
                display: block;
            }
        }

        .productInfoContainer {
            display: block;
        }

        .productName {
            font-size: 1.3rem;
        }

        .productQuantityContainer,
        .productPoints {
            display: none;
        }

        .productQuantityContainer,
        .productPoints,
        .productPointsTotal {
            justify-content: initial;
        }

    }

  }