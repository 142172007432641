.productCard--container {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;
  position: relative;
}
.productCard--container .product-discontinued-notice {
  color: rgba(0, 0, 0, 0.9);
  font-size: 1.6rem;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
  padding: 10px;
  width: calc(90% - 1rem);
}
.productCard--container .product-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 275px;
}
.productCard--container .liked-badge {
  color: #ff0000;
  position: absolute;
  right: 0px;
  top: -1rem;
}
.productCard--container .product-pts {
  color: #003366;
}
.productCard--container .btn-product--liked {
  background: transparent;
  box-shadow: none;
  float: right;
}
.productCard--container .btn-product--liked .material-icons {
  color: #ff0000;
}
.productCard--container .btn-product--addToCart {
  text-align: left;
  padding-left: 0;
}
.productCard--container .btn-product--addToCart:hover {
  /* TODO: Try and remove this override for material icons hover */
  background: transparent !important;
}
.productCard--container .btn-product--addToCart .material-icons {
  color: rgba(0, 0, 0, 0.9);
  float: left;
  margin-left: 0;
  padding-right: 1rem;
}
.productCard--container .product-pts--progressContainer {
  background: #A7A7A7;
  border-radius: 5px;
  min-height: 4px;
  position: absolute;
  bottom: 5px;
  width: calc(90% + 8px);
}
.productCard--container .product-pts--progressContainer .product-pts--progress {
  color: rgba(0, 0, 0, 0.2);
}
.productCard--container-discontinued .btn-product--liked {
  position: relative;
  top: -4rem;
}
@media screen and (min-width: 414px) {
  .productCard--container .product-pts--progressContainer {
    width: calc(90% + 4px);
  }
}
@media screen and (min-width: 1024px) {
  .productCard--container-discontinued .btn-product--liked {
    top: 0px;
  }
}
