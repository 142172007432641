body {
  overflow: auto !important;
}
.unauthorizedContainer {
  position: relative;
  height: 100%;
}
.unauthorizedContainer .unauthorizedContainerPadding {
  padding: 2rem;
}
.unauthorizedContainer .unauthorizedContent {
  width: 480px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.unauthorizedContainer .unauthorizedContent .unauthorizedLogo {
  margin: 0 0 6rem 0;
  text-align: center;
}
.unauthorizedContainer .unauthorizedContent .unauthorizedLogo img {
  max-height: 60px;
}
.unauthorizedContainer h1 {
  color: rgba(255, 255, 255, 0.95);
  font-weight: normal;
}
.unauthorizedContainer .input-container {
  border-color: none;
  background: rgba(0, 0, 0, 0.25);
}
.unauthorizedContainer .input-container .label {
  color: rgba(255, 255, 255, 0.95);
}
.unauthorizedContainer .input-container input {
  color: rgba(255, 255, 255, 0.95);
}
.unauthorizedContainer .input-container.input-focus .label,
.unauthorizedContainer .input-container.input-filled .label {
  color: rgba(179, 179, 179, 0.95);
}
.unauthorizedContainer .submitButton {
  background: #ffc72c;
  color: #0d2a8a;
  margin-top: 2rem;
}
.unauthorizedContainer .submitButton:hover {
  background: #f8b600;
}
.unauthorizedContainer .passwordTest {
  color: #fdfeff;
  line-height: 20px;
  margin: 0.5rem 0;
  word-wrap: break-word;
}
.unauthorizedContainer .passwordTest i {
  font-size: 21px;
}
.unauthorizedContainer .passwordTest--passed i {
  color: #33A532;
}
.unauthorizedContainer .passwordTest--failed i {
  color: rgba(0, 0, 0, 0.4);
}
.unauthorizedContainer .languageButton {
  border: none;
  background: none;
  outline: none;
  float: right;
  margin-top: 12px;
  text-decoration: underline;
  color: #00aaff;
  cursor: pointer;
  padding: 0px;
}
.unauthorizedContainer .errorMessageContainer {
  background-color: #FFCACA !important;
}
.unauthorizedContainer .forgotPasswordLink {
  color: #00aaff;
  display: inline-block;
  position: relative;
  top: -10px;
}
.unauthorizedContainer .forgotPasswordLink:hover,
.unauthorizedContainer .languageButton:hover {
  color: #0088cc;
}
@media screen and (max-width: 35.5em) {
  .unauthorizedContainer .unauthorizedContent {
    width: 100%;
  }
}
