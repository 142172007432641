@import 'styles/vars2';

.productFeedback--modal {
    background: #fff;
    position: absolute;
    padding: 6rem;
    font-size: 1.4rem;
    
    .btn-icon {
        position: absolute;
        width: 30px;
        height: 30px;
        top: 10px;
        right: 10px;
    }

}

.productFeedback--card {
    background: #FFF6DE;
    padding: 1rem 2rem;
    border-radius: 4px;
    margin-bottom: 2rem;
    border: 1px solid #FFFE6A;
}

@media screen and (min-width: $lg) {
    .productFeedback--modal {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 4px;
        box-shadow: 0 8px 16px rgba(0,0,0,0.4);

        .btn-icon {
            right: -15px;
            top: -15px;
        }

    }
}