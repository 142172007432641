.productPage {
  display: grid;
  grid-template-columns: 260px auto;
  grid-gap: 20px;
  padding: 20px;
}

.productGrid {
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
}

.productContainer {
  border: 1px solid #eee;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.75);
  font-size: 10pt;
  letter-spacing: 0.02em;
  margin: -1px 0 0 -1px;
  padding: 40px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}

// .productContainer.product--discontinued {
//   border: 1px solid lightcoral;
//   // margin: -2px 0 0 -2px;
// }

.product__orderCount {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #eee;
  border-radius: 12px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0 5px;
  box-sizing: border-box;
  z-index: 1;
}

.productIsNew {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 9pt;
  font-weight: bold;
  color: lightcoral;
  z-index: 1;
}

.productContainer--selected {
  /* box-shadow: 0px 0px 10px 4px #00ccff; */
  border: 3px solid lightskyblue;
  padding: 38px;
  z-index: 1;
  .productIsNew {
    top: 18px;
    left: 18px;
  }
}

.productImageContainer {
  height: 200px;
  position: relative;
  padding-bottom: 100%;
  height: 0px;
}

.productImage {
  position: absolute;
  top: 50%;
  left: 50%;
  // max-height: 200px;
  max-width: 100%;
  transform: translate(-50%, -50%);
}

.productBrand {
  font-size: 10pt;
}

.productName {
  font-weight: normal;
  font-size: 10pt;
}

.productDiscontinued {
  color: lightcoral;
  font-weight: bold;
  font-size: 10pt;
}

a {
  display: block;
  padding: 5px 0;
  text-decoration: none;
}

.productSelection {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    to left bottom,
    lightskyblue 50%,
    rgba(0, 0, 0, 0) 50%
  );
  padding: 3px 3px 22px 24px;

  i {
    color: #fff;
    // font-size: px;
  }
}

.productContainer--selected.productContainer--discontinued {
  border-color: lightcoral;

  .productSelection {
    background: linear-gradient(
      to left bottom,
      lightcoral 50%,
      rgba(0, 0, 0, 0) 50%
    );
  }
}

.category {
  font-size: 11pt;
  padding: 0.6rem 0;
  color: rgba(0, 0, 0, 0.75);
}
.category:hover {
  color: lightskyblue;
}

.tabContainer {
  height: 60px;
  display: flex;

  .tabButton {
    color: #0d2a8a;
    height: 20px;
    float: left;
    margin-right: 20px;
    letter-spacing: 0.02em;
  }

  .tabButton:hover {
    border-bottom: 2px solid #ccc;
  }

  .tabButton.active {
    border-bottom: 2px solid #0d2a8a;
  }
}

.productDetailsContainer {
  .productHeader {
    // display: flex;
    // justify-content: space-between;
    margin-bottom: 2rem;

    h3 {
      font-size: 1.3rem;
      color: rgba(0, 0, 0, 0.55);
      margin: 0 0 0.5rem 0;
    }
    h2 {
      margin: 0;
      font-size: 1.8rem;
    }

    p {
      text-align: justify;
    }

    .productPoints {
      margin: 2rem 0 0 0;
      font-size: 1.6rem;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.75);
      letter-spacing: 0.02em;
    }
  }

  h4 span {
    font-weight: normal;
    font-size: 1.2rem;
  }

  .productVariant {
    background: none;
    outline: none;
    appearance: none;
    min-width: 4.6rem;
    border: 1px solid #eee;
    border-radius: 0;
    margin: 0rem 0.5rem 1rem 0;
    padding: 1rem 1rem;
    cursor: pointer;
  }
  .productVariant--selected {
    border: 2px solid #999;
    min-width: 4.6rem;
    padding: 0.9rem 0.9rem;
  }

  .productQuantityContainer {
    display: flex;
    margin-bottom: 1rem;
    .productQuantityButton {
      outline: none;
      appearance: none;
      border: 1px solid #eee;
      background: none;
      width: 3rem;
      height: 3rem;
      border-radius: 0;
      cursor: pointer;
    }
    .productQuantity {
      width: 3rem;
      height: 3rem;
      text-align: center;
      line-height: 3rem;
    }
  }
}

.productContainer--single {
  .description,
  .description li {
    font-size: 1.4rem;
  }

  .description ul {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 64em) {
  .productGrid {
    grid-template-columns: repeat(5, 1fr);
  }

  .productContainer--single {
    display: grid;
    grid-template-columns: 2fr 1.2fr;
    gap: 2rem;

    .productImageContainer {
      max-width: 600px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
