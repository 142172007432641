@import "styles/vars2";

.pointsPooling__container {
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    text-align: center;
    background: $primary-blue;
    color: $primary-white;
    letter-spacing: 0.02em;
    margin-top: 1rem;

    .pointsPooling__title {
        text-transform: uppercase;
        margin-bottom: 1rem;
        color: lighten($primary-blue, 50);
        font-size: 1.2rem;
    }

    .pointsPooling__header {
        font-size: 2rem;
    }

    .pointsPooling__address {
        font-size: 1.4rem;
        margin-top: 1rem;
        color: lighten($primary-blue, 60);
    }
}

.pointsPoolingTable__container {
    position: relative;
    margin-bottom: 3rem;
}

.pointsPooling__expander {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -12px;
    appearance: none;
    outline: none;
    border: none;
    background: $primary-blue;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 0;
    margin: 0;
    box-shadow: 0 3px 3px 0px rgba(0,0,0,0.4);
    cursor: pointer;
    i {
        color: $primary-white;
        transform: rotate(0deg);
        transition: all 250ms;
    }
}

.pointsPooling__expander.pointsPooling__expander--expanded {
    i {
        transform: rotate(180deg);
        transition: all 250ms;
    }
}