@import 'styles/vars2';

.checkoutContainer {

    .orderInformation {

        .cartLine {

            display: grid;
            grid-template-columns: 64px auto 90px;
            gap: 1rem;
            padding: 1rem 0;
            border-bottom: 1px solid #eee;

            .productImageContainer--checkout {
                position: relative;
                width: 64px;
                height: 64px;

                .quantity {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: #eee;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 16px;
                    // border: 1px solid #fff;
                    font-size: 12px;
                    box-shadow: 0 0 4px rgba(0,0,0,0.3);
                }
            }

            .productDescription {
                display: flex;
                align-items: center;

                .trackingUrl {
                    color: lighten($primary-blue, 20);
                }
                .trackingUrl:hover {
                    color: $primary-blue;
                }

            }

            .productPoints {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                // color: $primary-blue;
            }
        }

        .total {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding: 2rem 0;
            font-size: 1.7rem;
            .pts {
                font-size: 1.2rem;
                color: rgba(0,0,0,0.7);
            }
            .points {
                font-size: 2rem;;
            }
        }

    }

    .shippingInformation {

        h2:first-of-type {
            margin-top: 0;
        }

        h2 {
            margin-bottom: 1rem;
        }

        h3 {
            margin-top: 4rem;
        }

        .shipTo,
        .deliverTo {
            border: 1px solid #eee;
            border-radius: 4px;
            padding: 1.5rem;
        }

        span {
            font-style: italic;
            margin-top: .5rem;
            display: block;
            color: rgba(0,0,0,0.7);
        }
        
    }

    .needHelpRight,
    .needHelpLeft {
        display: none;
    }

}

@media screen and (min-width:64em) {

    .checkoutContainer {

        display: grid;
        grid-template-columns: 1fr 1fr;

        .orderInformation {
            padding-left: 4rem;
        }

        .shippingInformation {
            grid-column-start: 1;
            grid-row-start: 1;
            border-right: 1px solid #eee;
            padding-right: 4rem;
        }

        .needHelpRight {
            display: block;
        }

    }

}

@media screen and (max-width:63.999em) {
    .checkoutContainer {
        .needHelpLeft {
            display: block;
        }
    }
}