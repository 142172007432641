@import "styles/vars2";

.installerPointsToggleContainer {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    button.installerPointsToggle {
        appearance: none;
        outline: none;
        background: #ccc;
        border: none;
        padding: .5rem 1rem;
        margin: 0;
        border-radius: 4px;
        letter-spacing: 0.02em;
        cursor: pointer;
    }

    button.installerPointsToggle--selected {
        background-color: $primary-blue;
        color: $primary-white;
    }

    button.installerPointsToggle:not(button.installerPointsToggle--selected):hover {
        background-color: #ddd;
    }

}

.cardDivider {
    height: 1px;
    background: rgba(0,0,0,0.1);
    margin: 2rem -2rem;
}

.totalPointsTotal {
    background-color: #f6f6f6;
    margin: 2rem -2rem -2rem -2rem;
    padding: 2rem;
    border-radius: 0 0 4px 4px;
    position: relative;
    // border-top: 1px dashed #ccc;
    // box-shadow: inset 0 0 2px 2px #000;
    overflow: hidden;
    font-weight: bold;
}
.totalPointsTotal:before {
    content: "";
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    box-shadow: 0 0 4px 1px rgba(0,0,0,0.4);
}