.promotion {
  text-align: center;
  margin-bottom: 0rem;
  position: relative;


  .promotionPadding__outer {
    height: 70px;
    position: relative;

    .promotionPadding__inner {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
    }
  }

  .promotion__title {
    font-size: 2.1rem;
    color: #0d2a8a;
    margin-bottom: .5rem;
  }

  .promotion__dates {
    font-size: 1.3rem;
    color: rgba(0,0,0,0.75);
  }

  .promotion__description {
    font-size: 1.7rem;
    margin: 2rem 0;
  }

  .promotion__familyLines {
    font-size: 1.3rem;
    font-weight: bold;
  }

  .promotion__logos {
    background: #fafafa;
    margin: 2rem -2rem -2rem -2rem;
    border-radius: 0 0 4px 4px;
    padding: 2rem;
    border-top: 1px solid #eee;
  }

  .promotion__checkbox {
    position: absolute;
    right: .8rem;
    top: .8rem;
    border: 2px solid #ccc;
    border-radius: 4px;
    height: 18px;
    width: 18px;
    i {
      line-height: 18px;
      font-size: 18px;
      color: #fff;
    }
  }

  .promotion__checkbox--checked {
    background: #ffc72c;
    border-color:#ffc72c;
  }

}

.promotion--checked {
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.3);
}

.promotion--unchecked {

  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);

  .promotion__title {
    color: desaturate(#0d2a8a, 100%);
  }

  .promotion__logos {
    img {
      filter: grayscale(100%);
    }
  }

}