/* NOTE: This line below is required for MD icons to appear */
@import url('https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css');
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v134/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
html {
  font-size: 62.5%;
}
root,
html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 1.2rem;
  background: #0d2a8a;
  color: rgba(0, 0, 0, 0.9);
  overflow-y: scroll;
}
h1,
h2,
h3 {
  color: #0d2a8a;
  font-weight: normal;
}
h2 {
  margin-top: 2.6rem;
}
.material-icons {
  font-feature-settings: 'liga' 1;
}
ul {
  margin-top: 0;
}
ul li {
  font-size: 1.8rem;
}
/* Start utilities */
.hidden,
.grid.hidden {
  display: none;
}
.text-center {
  text-align: center;
}
.f-right {
  float: right;
}
.inline-link {
  color: #0d2a8a;
  display: inline;
}
.full-width {
  min-width: 100%;
}
/* NOTE: The 20 in the naming here is to represent 20 as the pixel value in case more utility classes want to leverage this convention */
.pad-t-20 {
  padding-top: 1.25em;
}
.pad-b-20 {
  padding-bottom: 1.25em;
}
.push-b-0 {
  margin-bottom: 0;
}
/* End utilities */
/* Start header bar */
.header {
  height: 60px;
  background: #0d2a8a;
  color: rgba(255, 255, 255, 0.95);
  padding: 10px 0;
  font-size: 2em;
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0px auto;
  z-index: 100;
  text-align: center;
}
.header .store-login--account {
  position: absolute;
  left: 1em;
  top: 1.25em;
  font-size: 1.4rem;
  text-align: left;
}
.header .logo {
  position: relative;
}
.header .logo a {
  display: inline-block;
}
.header .logo img {
  max-height: 40px;
}
/* End header bar */
.mainContainer {
  height: 100%;
}
.mainContainer--store .header {
  height: 140px;
}
.mainContainer--store .header .store-login--account {
  top: 6em;
}
.mainContainer--store .main.store {
  padding-top: 100px;
}
/* Start main content area */
.main,
.main.installer,
.main.store {
  /* NOTE: This syntax is shorthand for top value of 0, side values of 20px and bottom value of 10px */
  padding: 2rem;
  background: #fff;
  box-sizing: border-box;
  margin-top: 60px;
  font-size: 1.4rem;
  position: relative;
  min-height: 84vh;
  padding: calc(2rem + 1rem);
}
.main.store {
  padding: calc(2rem + 40px);
}
.contentContainer {
  max-width: 1280px;
  margin: 0 auto;
}
.contentContainer--breadcrumb-padding {
  padding-top: 2rem;
}
.contentContainer--login,
.contentContainer--forgot {
  max-width: 640px;
}
.contentContainer--login label {
  color: rgba(255, 255, 255, 0.95);
}
.primary--heading {
  color: #0d2a8a;
}
.linked-card--container {
  text-decoration: none;
}
.header-actions {
  position: absolute;
  top: 4rem;
  right: 3rem;
}
.header-actions .btn-cart:hover {
  /* TODO: Try and remove this override for material icons hover */
  background: transparent !important;
}
.header-actions .btn-cart .btn-cart--desc {
  position: relative;
  left: -2.8rem;
  top: 2.4rem;
  text-decoration: underline;
}
.header-actions .btn-cart .material-icons {
  color: #0d2a8a;
  display: block;
  float: left;
}
.header-actions .btn-liked {
  float: right;
  position: absolute;
  top: 1rem;
  right: 0;
}
.header-actions .btn-liked:hover {
  /* TODO: Try and remove this override for material icons hover */
  background: transparent !important;
}
.header-actions .btn-liked .material-icons {
  background: transparent;
  color: #ff0000;
}
.header-actions .liked-count {
  background: #0d2a8a;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.95);
  position: absolute;
  top: 0;
  right: -1rem;
  width: 20px;
  height: 20px;
  text-align: center;
}
/* Start promotions list */
.contentContainer--promos .tabSelectorContainer {
  min-width: 100%;
}
.contentContainer--promos .tabSelectorContainer .tabSelectorButton {
  width: 33.33333333%;
}
.contentContainer--promos .promotions-list .card .promo-sales-progress {
  display: block;
  height: 4px;
  position: relative;
  left: -2rem;
  bottom: -2rem;
  background: #33A532;
}
.contentContainer--promos .promotions-list .promo-name {
  position: relative;
  top: 0;
  color: rgba(0, 0, 0, 0.9);
  font-size: 2rem;
  padding-bottom: 4rem;
}
/* End promotions list */
/* Start register screen */
.contentContainer--register .installer-promo--container,
.contentContainer--enroll .installer-promo--container {
  grid-column: 1 / span 3;
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.contentContainer--register .installer-promo--container .input-container,
.contentContainer--enroll .installer-promo--container .input-container {
  display: grid;
  position: relative;
  top: -2rem;
  border: none;
}
.contentContainer--register .installer-promo--container .input-container input[type='checkbox'],
.contentContainer--enroll .installer-promo--container .input-container input[type='checkbox'] {
  grid-column: 6;
}
.contentContainer--register .btn-promo--action,
.contentContainer--enroll .btn-promo--action {
  grid-column: 3;
  text-align: right;
  margin-top: 4px;
}
.contentContainer--register .submitButton--fullWidth,
.contentContainer--enroll .submitButton--fullWidth {
  grid-column: 2;
}
.contentContainer--register .new-installer-subheading {
  margin-top: 0;
}
/* End register screen */
/* Start enroll screen */
/* End enroll screen */
/* Start installer details screen */
.contentContainer--installer .tabSelectorContainer {
  min-width: 100%;
}
.contentContainer--installer .tabSelectorContainer .tabSelectorButton {
  width: 50%;
}
/* End installer details screen */
/* Start rewards screen */
.contentContainer--rewards .rewards-search--formContainer .submitButton {
  float: right;
  padding: 1rem;
  position: relative;
  top: -6rem;
  color: #000;
  margin-right: 1rem;
  background: transparent;
}
/* End rewards screen */
/* Start rewards product screen */
.contentContainer--product .selected-product--infoContainer {
  position: relative;
}
.contentContainer--product .selected-product--infoContainer .product-pts {
  font-size: 2.4rem;
  position: absolute;
  top: 2rem;
  left: 2rem;
}
.contentContainer--product .selected-product--infoContainer .product-image--lg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 500px;
}
.contentContainer--product .selected-product--infoContainer .product-details--container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: #0d2a8a;
  height: max-content;
  padding: 1rem;
}
/* End rewards product screen */
/* Start checkout screen */
.contentContainer--checkout .checkout-balance {
  margin-top: 0;
  text-align: center;
}
/* NOTE: This product image treatment should be consistent between checkout and order summary screens; hence this rule not being nested within either */
.product-image--sm {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 60px;
  height: 60px;
}
/* End checkout screen */
/* Start order summary screen */
/* End order summary screen */
/* Start FAQ screen */
.contentContainer--faq li:not(:first-of-type) {
  padding-top: 1rem;
}
.contentContainer--faq .answer li {
  color: #0d2a8a;
  padding-top: 1rem;
}
/* End FAQ screen */
.disabled-link {
  pointer-events: none;
}
/* End main content area */
/* Start media queries */
@supports (display: grid) {
  @media screen and (min-width: 48em) {
    .grid {
      display: grid;
      grid-gap: 3rem;
    }
    .grid-gap-sm {
      grid-gap: 1rem;
    }
    .grid-1-1 {
      grid-template-columns: 1fr 1fr;
    }
    .grid-1-2 {
      grid-template-columns: 1fr 2fr;
    }
    .grid-one-third {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-1-3 {
      grid-template-columns: 1fr 3fr;
    }
    .grid-2-1 {
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto;
    }
    .mainContainer--store .header {
      height: 60px;
    }
    .mainContainer--store .header .store-login--account {
      top: 1.25em;
      left: 4em;
    }
    .mainContainer--store .header .nav__toggle,
    .mainContainer--store .header .btn-back {
      top: 50%;
    }
    .mainContainer--store .main.store {
      padding-top: 60px;
    }
    .contentContainer--promos .installerLogoContainer,
    .contentContainer--installers .installerLogoContainer {
      grid-row: 1 / span 2;
    }
    .contentContainer--promos .promotions-list .promo-name {
      top: 0px;
      padding-bottom: 1rem;
    }
    .contentContainer--liked .grid-liked-products {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (min-width: 64em) {
    .mainContainer--store .main.store {
      padding-top: 60px;
    }
    .header-actions {
      position: relative;
    }
    .header-actions .btn-cart {
      position: absolute;
      top: -3rem;
    }
    .header-actions .btn-cart .btn-cart--desc {
      position: relative;
      left: -5.5rem;
      text-decoration: underline;
    }
    .header-actions .btn-liked {
      float: right;
      position: absolute;
      top: -2rem;
      right: -2rem;
    }
    .header-actions .liked-count {
      top: -3rem;
      right: -3rem;
    }
    .contentContainer--liked .grid-liked-products {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media screen and (min-width: 70.25em) {
    .header-actions .btn-cart {
      position: absolute;
      top: -3rem;
      left: 6rem;
    }
    .header-actions .btn-cart .btn-cart--desc {
      position: relative;
      left: -7.4rem;
      text-decoration: underline;
    }
  }
}
