@import "styles/vars2.scss";

.promo-info-subheading {
    color: $primary-blue;
    // font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 2px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
}

.card p {
    margin: 0;
}

.card div p:not(:first-of-type) {
    padding-top: 10px;
}

.card-details-list {
    p {
        padding-bottom: 1rem;
    }
}