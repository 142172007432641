@import 'styles/vars2';

@media screen and (min-width: 64em) {
    .orderHistoryCard {
        display: grid;
        grid-template-columns: 120px 200px 200px 1fr 1fr;
    }

    .orderHistoryCard--cancelled {
        background: #eee;
        text-decoration: line-through;
    }
}