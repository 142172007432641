@import 'styles/vars2';

.pill {
    padding: .2rem .6rem;
    background: rgba(0,0,0,0.1);
    display: inline-block;
    border-radius: 1000px;
    font-size: 1.1rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}

.pill.pillVariant--success {
    background-color: $success-green;
    color: $primary-white;
}

.pill.pillVariant--on_hold {
    background-color: darken($primary-red, 20);
    color: $primary-white;
}