/* Variable definitions */
.input-container {
  display: block;
  border-radius: 4px;
  margin: 0 0 1.5rem 0;
  position: relative;
  height: 4.5rem;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.input-container .material-icons {
  position: absolute;
  top: 1rem;
  right: 2rem;
}
label {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}
label .label {
  font-size: 1.4rem;
  position: absolute;
  line-height: 4.5rem;
  -webkit-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  color: rgba(0, 0, 0, 0.65);
}
.input-container.input-focus .label,
.input-container.input-filled .label {
  top: 0px;
  font-size: 1.2rem;
  line-height: 2.4rem;
  transition: all 0.1s ease;
}
.input-container.input-focus {
  border: 1px solid #0d2a8a;
}
.required-hint:after {
  content: " *";
  color: #ff0000;
  position: absolute;
  margin: 0px 0px 0px 80%;
  font-size: 1.2em;
  top: 0.5rem;
  right: 0.8rem;
}
select,
input {
  position: relative;
  background: none;
  border: none;
  outline: none;
}
select {
  width: 100%;
  font-size: 1.6rem;
  height: 100%;
  padding: 0 1rem;
}
input {
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  text-indent: 1.5rem;
}
select:disabled,
input:disabled {
  background: #e8e8e8;
  color: #828282;
}
.input-error {
  border-color: #ff0000;
}
.errorMessage {
  color: #ff0000;
}
.changed-field,
.changed-field--details {
  color: #ff0000;
}
.changed-field input,
.changed-field--details input,
.changed-field select,
.changed-field--details select {
  border: 1px solid #ff0000;
  border-radius: 4px;
}
.registration-warning {
  color: #ff0000;
}
.input-search {
  max-width: 40%;
  position: absolute;
  right: 2px;
  top: 2px;
  line-height: 1rem;
}
@media screen and (min-width: 48em) {
  .input-search {
    max-width: 20%;
  }
}
